<template>
  <Container class="w-full">
    <div class="flex flex-col items-center justify-between w-full px-4 py-6 my-12 text-white md:p-12 lg:flex-row rounded-3xl bg-gradient-black-01">
      <div class="flex items-center space-x-8">
        <h3 class="text-3xl font-medium text-center md:text-left">
          Want to share this article?
        </h3>
      </div>

      <BlogShareLinks class="mt-8 lg:mt-0" />
    </div>
  </Container>
</template>
